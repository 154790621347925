/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Resets the scroll to the top of the page on each link click
exports.shouldUpdateScroll = () => {
  for (let scroll in window.sessionStorage) {
    if(scroll.includes('@@scroll', 0)) {
      window.sessionStorage.removeItem(scroll)
    }
  }
  return false
}
exports.onRouteUpdate = () => {
  window.setTimeout(() => {
    window.scrollTo(0,0)
  },200)
}